@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,900;1,100;1,200;1,400;1,500;1,700&display=swap");

body * {
  font-family: "Poppins", sans-serif !important;
}
@layer components {
  .provider-profile-div {
    @apply px-6 py-6 bg-white rounded-lg shadow-provider-proifle;
  }

  .box {
    @apply border border-black border-solid;
  }
}

@layer utilities {
  .border-top {
    @apply border-t-[1px] border-x-0 border-b-0 border-solid;
  }

  .main-tab-text {
    @apply text-[#333B7A] font-semibold text-[28px] text-center py-[36px];
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
}

@layer utilities {
  .border-top {
    @apply border-t-[1px] border-x-0 border-b-0 border-solid;
  }

  .main-tab-text {
    @apply text-[#333B7A] font-semibold text-[28px] text-center py-[36px];
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #333b7a;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333b7a;
  /* border-radius: 10px; */
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}

.recharts-surface {
  overflow: visible;
}
